@charset "utf-8";
/* CSS Document */
@import url('https://fonts.googleapis.com/css?family=Oswald:200,300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');
*{margin:0px;padding:0px;}
html{ min-height:100%; height:auto ;}
body{width:100%;margin:0px;padding:0px; font-family: 'Open Sans', sans-serif ; font-size:12px; color:#; min-height:100%; height:auto;}
h1,h2,h3,h4,h5,h6,ul,li,div,p{margin:0px;padding:0px;}
ul li{list-style-type:none; }


.btn-danger, .btn-info, .btn-primary, .btn-success, .btn-default, a, a:active, a:focus, a:hover{-webkit-transition: all 0.5s ease-in-out;-moz-transition: all 0.5s ease-in-out;-o-transition: all 0.5s ease-in-out;-ms-transition: all 0.5s ease-in-out;transition: all 0.5s ease-in-out;}

@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}

#mydata1_filter { display:none;}
.circle{ width:25px; height:25px; border-radius:50px;display:inline-block;margin-right:5px;}


nav.navbar.bootsnav ul.nav > li > a {

    border-bottom-width: 5px;
    border-bottom-style: solid;
}

nav.navbar li.dropdown ul.dropdown-menu > li a:hover{font-weight:500;padding: 10px 0px 10px 5px;}

nav.bootsnav .megamenu-content .title{font-weight:500}

.select-style:after{ content: '';width: 10px;height: 20px;display: inline-block;right: 0;margin-right: 8px;float: right;top: 0px;margin-top: -40px;
padding: 5px; z-index:1000001; }
.select-style1:after{ content: '';width: 10px;height: 20px;display: inline-block;right: 0;margin-right: 8px;float: right;top: 0px;margin-top: -26px;
padding: 5px; z-index:1000001; }
.select-style{margin: 0;border-radius: 3px;overflow: hidden;font-size:14px;vertical-align:middle; margin-top:0px; }
select{ padding: 14px 8px;width: 100%;-webkit-appearance: none;-moz-appearance: none;appearance: none;}
option{ padding:8px; }

nav.navbar.bootsnav.navbar-fixed .logo-scrolled{width:85% !important;}
nav.navbar.bootsnav .navbar-toggle{ font-size:28px !important;}

nav.navbar.bootsnav.navbar-fixed{min-height:70px;}
nav.navbar.bootsnav.no-background.white ul.nav > li > a{background: #fff ;border-bottom-style: solid;border-bottom-width:4px ;min-height: 76px;  font-family: 'Open Sans', sans-serif !important; font-size:13.4px; letter-spacing:0px;  text-align:center}
nav.navbar.bootsnav ul.nav > li > a{font-family: 'Open Sans', sans-serif; font-size:15px; letter-spacing:0px; font-weight:normal}


.navbar-nav > li{border-width: 1px; border-left-style: solid;}

nav.navbar.bootsnav ul.nav > li.dropdown > a.dropdown-toggle::after {margin-left: 0px; display:block; text-align:center;margin-top: 0px;}
.nav > li{min-width: 120px;min-height: 71px; }
nav.navbar.bootsnav ul.nav > li > a{ padding:  12px 5px 3px 5px;;}
.attr-nav{ margin-top:0px}

nav.navbar.bootsnav ul.nav > li > a{text-align: center;color: #fff;font-size: 13.4px;min-height: 76px;min-width: 20px;}

#btnFullscreen{ margin-top: 22px;padding: 2px 7px; border: none;font-size: 18px;border-radius: 3px;}
.attr-nav > ul > li.dropdown ul.dropdown-menu{ left:-200px ;}
.search {margin-left: -14px !important;}
header .top-search{ box-shadow: 5px 7px 5px #062842}
.navbar-brand{padding: 14px 15px;}

.attr-nav > ul > li > a{padding: 22px 15px;}
.side{padding:0px;text-align: justify; }

.body_section{display: inline-block; width:100%; min-height:200px; margin-top:62px; padding:20px 2px;  margin-bottom:30px}
.graph_report_tabing, .heading{ float:right; width:100%; min-height:30px;}

.graph_report_tabing ul li{ float:left}
.graph_report_tabing ul li a{margin-top: 0.5px;font-weight: 500; font-family: 'Open Sans', sans-serif ;font-size:14px;text-decoration:none; float:left; padding:5px 15px;
border-top-right-radius:6px; border-top-left-radius:6px; border-bottom-left-radius:0px;border-bottom-right-radius:0px;
}
.ratio-sec h6 {font-size: 12px;}
.graph-sec{padding:2px; border-radius:6px;  width:100%; min-height:20px;display:inline-block; margin-top:5px; margin-bottom:7px;}

.graph_report_tabing ul > .active a{font-weight:bold; border-top-left-radius: 8px;border-top-right-radius: 8px;
display:inline-block}
.heading{padding:4px 8px; font-size:14px; font-weight:500; border-top-left-radius:6px; border-top-right-radius:6px; border-bottom-left-radius:0px; border-bottom-right-radius:0px;}
.form-control{color: #0a7d8c;font-size: 13px;border-radius: 4px ;  }
.graph_report_tabing{border-top-left-radius:6px ; border-top-right-radius:6px ;
background: -moz-linear-gradient(to bottom, rgb(4, 90, 87) 0%,rgba(20, 166, 178, 0.8) 1%,rgba(86, 191, 220, 0.8) 50%,rgba(16, 141, 184, 0.8) 97%,rgba(190, 220, 226, 0.8) 99%,rgba(8,129,165,0) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(to bottom, rgb(4, 90, 87) 0%,rgba(20, 166, 178, 0.8) 1%,rgba(86, 191, 220, 0.8) 50%,rgba(16, 141, 184, 0.8) 97%,rgba(190, 220, 226, 0.8) 99%,rgba(8,129,165,0) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgb(4, 90, 87) 0%,rgba(20, 166, 178, 0.8) 1%,rgba(86, 191, 220, 0.8) 50%,rgba(16, 141, 184, 0.8) 97%,rgba(190, 220, 226, 0.8) 99%,rgba(8,129,165,0) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2d8ac4', endColorstr='#000881a5',GradientType=0 ); /* IE6-9 */}


.table-wraper{ padding:5px 22px;}

table.dataTable td {padding:2px 6px ;}
table.dataTable th {padding:0px 6px ;}

div.dataTables_wrapper div.dataTables_info {font-style: italic;}
.pagination{ margin-top:15px ;}

label{ font-weight:500 }
.identity-heading{  padding:20px;}
.identity-heading h4{font-size:20px}

.content{ width:100%; padding:20px; font-size:14px; display: inline-block;}
.sliding-content a, a:hover{ text-decoration:none;}
.sliding-content { text-decoration:none; margin-top:8px;}
.btn-back{padding:  20px 0px 0px 0px;min-height: 20px; z-index: 10001;display: inline-block; width: 100%;}
nav.navbar.bootsnav.navbar-fixed.no-background .logo-display {width: 85%;}
.ticket{width:100%; min-height:20px;float:left;margin:5px 10px 5px 0px;border-radius: 6px;max-height: 70px;overflow: auto;}

.min-max-width-left{ min-width: 80px;white-space:nowrap}
.min-max-width-right{width: 100%;  }

.ticket li{ display: table-cell;min-height: 70px;overflow: auto;text-align: center;height: 70px;}
.ticket li a{text-align: center; font-size:15px; font-weight:500;vertical-align:middle}
.ticket li a:hover{ text-decoration:none;}
.ticket h6{ padding: 2px 5px;font-size: 24px;}
select.input-sm {height: 30px; line-height: 18px;}

.table-bordered>thead>tr>th {border-bottom-color: transparent;}

.icon-pad{margin-top:0px; }
.icon-size{ font-size:25px ;}
.pass-word{ line-height:22px; font-weight:500 ; text-align:left;padding-left: 20px;font-size:14px;}
.all-pass{ margin-top:15px;}
.lock-icon{border-radius: 50px;padding: 3px 6px;font-size: 21px;color: #75f9fe;}
#login .input-group-addon{border-radius: 0px;}

.btn-success{padding: 14px 50px;display: inline-block;font-weight: 500;text-transform: uppercase;float: right;
margin: 12px 0px 12px 0px ; border-radius: 0px; }
.pass-word a:hover{ text-decoration:none t;}
.box-heading{max-width: 168px;margin: 0 auto;padding: 6px; margin-bottom:10px}


footer{ width:100%; min-height:20px;padding: 6px 0px;position: fixed; z-index:101;bottom: 0%;border-top-left-radius: 36px; border-top-right-radius: 36px;}

footer h6{color:#fff ;}
.btn-primary{border: none;padding: 7px 30px 8px 30px;;border-radius: 10px;border-radius: 75px;}
.btn-primary.active, .btn-primary:active, .open > .dropdown-toggle.btn-primary{
  border-radius:0px;  padding:5px 35px;}
.whom-btn-section{ width:100%; min-height:20px; display:flex}
.travel-info{ width:100%; min-height:20px; padding:10px; text-transform:capitalize; display: inline-block; }
.travel-info label h6{font-size:13px ;}
.font-strong{ font-size:14px; font-weight:500;width:230px; display:block}

.travel-info tbody{ border:none ;}
.form-head{   width:100%; display:block;margin-bottom:5px;}
.form-head u{min-height:40px; position: relative; text-decoration: navajowhite;display:block; }
.form-head u:after{content: "";width: 0px;height: 0px;position: absolute;right: 0%;bottom: 0%;left: 100%;top: 0%; }
label{ margin-bottom:0px ;}
.u-form-styl{min-height: 20px ;padding-top: 2px ;display: inline-block;min-width: 200px;position: relative;text-align: left;padding-left: 20px; float:left;margin-left: 16px;}

.form-heads{width:100%; display:block;}
.form-heads u{position: relative;
padding: 2px;text-decoration: none;min-width: 200px;max-width:1000px;display: inline-block;margin-top:12px;font-size: 12px;}
.form-heads u::after { content: ""; width: 0px; height: 0px; position: absolute;right: 0%;bottom: 0%; left: 100%; top: 0%;}

@media screen and (max-width:1500px) and (min-width: 1023px) {
/*nav.navbar.bootsnav.menu-center .navbar-header, nav.navbar.bootsnav.menu-center .attr-nav{ position:relative}*/
.nav > li{ min-height:75px ;    min-width: 100px;}
}

@media screen and (max-width:1223px) and (min-width: 993px) {
nav.navbar.bootsnav.navbar-fixed .logo-scrolled{ display:none}
	.nav > li {min-width:80px;}


}
@media screen and (max-width:992px) {
.logo-small{display: none !important;}
}
@media screen and (max-width:1390px) {
	.nav > li {
    min-width: 75px;}
	nav.navbar.bootsnav .navbar-toggle{margin-top: 16px; }
	nav.navbar.bootsnav .navbar-brand{margin-top: 6px !important;}
}
@media screen and (max-width:992px) and (min-width:992px) {
  nav.navbar.bootsnav.navbar-fixed .logo-scrolled{width:30% !important; margin: 0 auto;}
}
@media screen and (max-width:1279px) {
#btnFullscreen{display: none !important;}
.logo-display, .logo-scrolled{display: none !important;}
.logo-small{margin-top: -10px;}
.navbar-nav > li{
-webkit-border-image: -webkit-linear-gradient(bottom, #fbfbfb, #fff, rgba(255,255,255, 0)) 1 10%;
-moz-border-image: -moz-linear-gradient(bottom, #fbfbfb, #fff, rgba(255,255,255, 0)) 1 10%;
-o-border-image: -o-linear-gradient(bottom,#fbfbfb, #fff, rgba(255,255,255, 0)) 1 10% ;
border-image: linear-gradient(to top, #fbfbfb, #fff, rgba(255,255,255, 0)) 10% ;}
}
@media screen and (min-width:1280px) {
  .logo-small{display: none !important;}
}

@media screen and (max-width:400px) {
nav.navbar.bootsnav .navbar-brand{ float:left !important;margin-left: 28px !important;margin-top: 10px !important;}
#mydata5_length {
    float:none !important;
}
.all-ticket{margin:5px 10px ;}

#mydata6_length {
    float:none !important;
}

#mydata4_length {
    float: none;
}
.search{margin-left: -3px;}
.btn-success{
    padding: 14px 20px;}

.form-heads u{ max-width:250px}

}

@media screen and (max-width:992px) {
nav.navbar.bootsnav .navbar-nav > li > a{right: -3px;height: 68px !important;}

.ticket h6{ font-size:18px}

}
@media screen and (max-width:1150px) {
#mydata5_length { float:left !important;}
#mydata6_length { float:left !important;}
#mydata4_length {float: left !important;}
}

.total-form-sec{width:100%; min-height:20px;}
.one-styl{ margin-bottom:15px}
.group-info{margin-top: 38px; padding:20px 18px 20px 0px; width: 100%;min-height: 20px;display: inline-block;}

.parametter-line{ margin:15px 0px 60px 0px;width:100%; height:7px; border-radius:20px; position:relative;}

.parametter-arrow{left:49%;margin-top: -27px;position: absolute;width: 20px;height: 20px;top: 0%;margin-left: -13px;}

.parametter-arrow-back{left: 49%;margin-top: -0.4%;position: absolute;width: 30px;height: 30px;}
.cont h6{ font-size:14px; font-weight:500; margin-top:5px;}
.cont{ width: 120px;min-height: 10px;text-align: center;margin-left: -56px;}
.parameter-detail{ width:100%; min-height:200px;}

.parameter-detail ul li{ float:left; margin-bottom:55px; font-size:13px; font-weight:500}
.parametter-up-sec{ width:100%; min-height:100px;}

.attch, .load-icon {padding: 4px 6px; border: none; border-radius: 5px;margin-left: 8px; margin-bottom: 5px ;}
.attach-styl1 {font-size: 20px; padding: 9px 6px; text-align: center; min-width: 40px; min-height: 40px;}
.box input[type="file"]{ display:none;}



.input-group-addon{text-align: center;border: 1px solid #e2e2e2;}
.form-inner-sec {width: 100%; min-height: 20px;border-radius: 5px; margin: 6px 0px; padding: 20px;}

.hod-circle{ width:100%; min-height:200px; padding:12px 0px; }
.circle-cont{ width: 100%; min-height:50px; margin-top:-131px; font-weight:400;}
.hod-circle-group{  display:flex;margin: 0 auto;}
.hod-circle-group1{ width:100%; display:flex;margin:0 auto; padding:15px 15px;}
.finance-circle{ width:170px; height:170px;border-radius:50%;margin:20px 0px }
.finance-cont{ margin-top:28px; }


.cekbox-field{width:100%; min-height:20px;}


.employee-hod-circle{width:145px; min-height:120px; max-width:100%; padding:36px 0px;margin: 0 auto; display:block}
.employe-cont{  min-height:50px; font-weight:400; width:100%}


.employee-set{ float: left;min-width: 140px;height: 149px;margin: 0px 10px;text-align: center;}
.label-sec{ width: 100%; min-height: 20px; padding:20px 10px}

.btn-warning {padding: 6px 22px;  border-radius: 8px;margin-right: 7px;margin-left:7px;}


.inner-section { min-height: 120px;}
.form-group {border-radius: 4px;box-shadow: 0px 0px 0px 0px #fff}



.nav-side-menu {
    overflow: auto;
    font-family: verdana;
    font-size: 12px;
    font-weight: 200;
    top: 0px;
    height: 100%;
}
.nav-side-menu .brand {
    line-height: 50px;
    display: block;
    text-align: center;
    font-size: 14px;
}
.nav-side-menu .toggle-btn {
    display: none;
}
.nav-side-menu ul,
.nav-side-menu li {
    list-style: none;
    padding: 0px;
    margin: 0px;
    line-height: 40px;
    cursor: pointer;
    /*
      .collapsed{
         .arrow:before{
                   font-family: FontAwesome;
                   content: "\f053";
                   display: inline-block;
                   padding-left:10px;
                   padding-right: 10px;
                   vertical-align: middle;
                   float:right;
              }
       }
    */
}
.nav-side-menu ul :not(collapsed) .arrow:before,
.nav-side-menu li :not(collapsed) .arrow:before {
    font-family: FontAwesome;
    content: "\f078";
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle;
    float: right;
}

.nav-side-menu ul .sub-menu li,
.nav-side-menu li .sub-menu li {
    border: none;
    line-height: 28px;
    margin-left: 0px;
}

.nav-side-menu ul .sub-menu li:before,
.nav-side-menu li .sub-menu li:before {
    font-family: FontAwesome;
    content: "\f105";
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle;
}
.nav-side-menu li { padding-left: 0px;text-align: left;margin-bottom:1px;border-top-right-radius: 10px; border-bottom-right-radius: 10px;}
.nav-side-menu li a {border-left-width: 7px; border-left-style: solid;display: block; text-decoration: none;font-weight: 500;padding-left: 33px;
}
.nav-side-menu li a i {
    padding-left: 10px;
    width: 20px;
    padding-right: 20px;
}
.nav-side-menu li:hover {
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}
@media (max-width: 767px) {
    .nav-side-menu {
        position: relative;
        width: 100%;
        margin-bottom: 10px;
    }
    .icon-conts h4{font-size:16px;}
    .icon-conts h5{font-size:14px; }
    .nav-side-menu .toggle-btn {
        display: block;
        cursor: pointer;
      margin-bottom: 5px;
        right: 10px;
        top: 10px;
        z-index: 10 !important;
        padding: 3px;
        width: 40px;
        text-align: center;
    }
    .brand {
        text-align: left !important;
        font-size: 22px;
        padding-left: 20px;
        line-height: 50px !important;
    }
}
@media (min-width: 767px) {
    .nav-side-menu .menu-list .menu-content {
        display: block;
    }
}
.header1{ width: 100%; min-height: 20px;padding:5px 10px; margin-top:10px;border-top-left-radius: 8px;border-top-right-radius: 8px;font-weight: 500; font-size:14px;}
.back-border{width:100%; min-height:20px; border-radius: 12px; border-top-left-radius: 0px; display: inline-block;}

.ratio-sec{width: 100%; min-height:10px;  text-align:center; }
.hide-bullets {list-style:none;}

.img-border{border:5px solid #fff !important}
.main_area {width: 100%;min-height: 20px;display: inline-block;; padding: 10px;
    box-shadow:0px 1px 3px 1px #c2bfbf;margin-top: 10px;}


.info-box{margin:10px 0px;width:100%; min-height:110px;
  box-shadow:0px 1px 3px 1px #eaeaea; padding:10px; display: inline-block;}
  .info-box p{font-size: 13px;line-height: 20px;}
.info-box h6{margin-bottom: 8px; }
.fa-phone{font-size:15px;padding-right: 6px;}
.fa-envelope{font-size:15px;padding-right: 6px;}

.double-circle{width:75px; height:75px; border-radius: 100%;border: double #0890ad; text-align: center; padding-top:17px;}
.dash-tablist .nav-tabs > li > a{margin-right:3px;font-size: 15px;text-align: center; }
.dash-tablist .nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover { border: none;}
.dash-tablist{width:100%;min-height:20px;}
.dash-tablist .nav > li > a{min-height:44px;border: 1px solid ; margin-right:3px;}
.dash-tablist .nav > li{min-height:20px;}
.dash-tablist .nav-tabs { border-bottom: none;margin-top: 10px;}
.marg-arrow:before{left:57% !important}
.custom-border{width: 100%;min-height: 30px;border-left: 1px solid #ccc;border-right: 1px solid #ccc;border-top-right-radius: 5px;
border-top: 1px solid #ccc;}

.icon-conts h4{font-size:16px;}
.icon-conts h5{font-size:13px; text-transform: uppercase;}
.first-inbox-sec{width: 100%; min-height:20px; border-bottom:1px solid #dddada; }
nav.navbar.bootsnav ul.dropdown-menu.megamenu-content .content ul.menu-col li a:hover{padding: 10px 0px 10px 5px;}
.form-control{background:#fff ;    border: 1px solid #ececec;}
     input.form-control{box-shadow: none ;}
     .jssort01 .p {
     	position: absolute;
     	top: 0;
     	left: 0;
     	width: 72px;
     	height: 72px;
     }
     .jssort01 .t {
     	position: absolute;
     	top: 0;
     	left: 0;
     	width: 100%;
     	height: 100%;
     	border: none;
     }
     .jssort01 .w {
     	position: absolute;
     	top: 0px;
     	left: 0px;
     	width: 100%;
     	height: 100%;
     }


     .jssort01 .pav .c {
     	top: 2px;
     	_top: 0px;
     	left: 2px;
     	_left: 0px;
     	width: 68px;
     	height: 68px;
     	border: #000 0px solid;
     	_border: #fff 2px solid;
     	background-position: 50% 50%;
     }
     .jssort01 .p:hover .c {
     	top: 0px;
     	left: 0px;
     	width: 70px;
     	height: 70px;
     	border: #fff 1px solid;
     	background-position: 50% 50%;
     }
     .jssort01 .p.pdn .c {
     	background-position: 50% 50%;
     	width: 68px;
     	height: 68px;
     	border: #000 2px solid;
     }
     .jssort01 .c {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 68px;
    height: 68px;
    border: #000 2px solid;
    box-sizing: content-box;
    background:url(../../../images/t01.png) -800px -800px no-repeat;
    _background: none;
}

#jssor_1{max-width:100%;;}

@media screen and (max-width:1239px) and (min-width:768px){
  .all-ticket { max-width: 46%; float: left;}
  .ticket-section {  display: inline-block ;}
  .sm-ticket-width{max-width: 44% ;}
}

@media screen and (max-width:767px) and (min-width:200px){

  .all-ticket { max-width: 95%; float: left;}
  .ticket-section {  display:inline-block ;}
}
@media screen and (min-width:1240px){
  .all-ticket{max-width: 25%;}

}

.all-ticket {position: relative;min-height: 60px;display: flex ; padding: 10px; border-radius: 6px; flex-basis: 22%;;  justify-content: space-around;
margin:5px 15px; width: 100%;}
.each-ticket{flex:1; margin:auto; align-items: center;}

.all-ticket  li {align-items: center; text-align: center;margin:auto;line-height: 16px;}

.all-ticket  li a{color:#fff; font-size:14px}
.all-ticket  li a:hover{color:#fff}
.ticket-section{display: flex; width: 100%;min-height: 20px;flex-wrap: wrap;}
.each-ticket-numeric{font-size:25px !important;font-weight: 500; white-space: nowrap;}
.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: none ;}
.tagsinput1{width:100%; min-height: 20px; background: #fff; border-bottom: 1px solid #ccc;padding:10px 0px; display: inline-block;font-size:16px;}
.cross-sign{width: 22px;height: 22px;background: #ea6060;border-radius: 100%;
    color: #fff;padding: 3px 5px;display: inline;font-size: 15px;}
.checkbox label, .radio label{font-size: 14px ;}
.hide-sec h6{font-weight: 500;font-size: 13px;}
.high-light-sec{width:100%; min-height: 20px; display: inline-block; padding: 15px 0px;}

.nav-side-menu ul, .nav-side-menu li{min-height: 30px;border-radius: 0px;}
.nav-side-menu ul .active, .nav-side-menu li .active {min-height: 30px;}
.nav-side-menu li a { min-height: 30px;}

footer h6{font-weight: 500}

.sign-up-section{font-family: 'Open Sans', sans-serif ;width: 100%; display: flex;align-items: center;background: #fff;height: 100%; min-height: 100%; height: -webkit-fill-available;}
.main-form-section{width: 100%;background: #fff; }
.main-form-section h1{font-family: 'Open Sans', sans-serif ;font-weight: 300;letter-spacing: 2px;color: #13b7dd;font-size: 62px;line-height:76px;}
.main-form-section h4{color:#00446c; padding: 11px 0px; font-weight: 600;}
.main-form-section h4:before{content: ''; width: 100px; height: 5px; background: #00446c; display: inline-block; left: 0px; margin-right: 10px}
.main-form-section h4:after{content: ''; width: 100px; height: 5px; background: #00446c; display: inline-block; right: 0px; margin-left: 10px}

.main-form-section .list-group-item:first-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.main-form-section .list-group-item:last-child {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}
.main-form-section .list-group{margin: 20px 0px 0px 0px;font-family: 'Open Sans', sans-serif ;}
.main-form-section .list-group-item{font-size: 16px;font-family: 'Open Sans', sans-serif ;padding: 12px 15px;}
.main-form-section .list-group-item.active{font-size:18px; background: #00446c; color: #fff;padding: 6px 0px}
.main-form-section span{width: 40px; height: 40px; display: inline-block;
    text-align: center;color: #fff; padding: 8px;border-right: 1px solid #d8d8d8;}

.main-form-section span:after{content: '';display: block; border-right:10px solid #13c3df; border-top: 10px solid #13c3df; border-bottom: 10px solid transparent;
    border-left: 10px solid transparent; right: -8px; position: relative; top: -2px;}

    .main-form-section .yellow-span:after{content: '';display: block; border-right:10px solid #ff9900; border-top: 10px solid #ff9900; border-bottom: 10px solid transparent;
        border-left: 10px solid transparent; right: -8px; position: relative; top: -2px;}

        .main-form-section .green-span:after{content: '';display: block; border-right:10px solid #49c583; border-top: 10px solid #49c583; border-bottom: 10px solid transparent;
            border-left: 10px solid transparent; right: -8px; position: relative; top: -2px;}

            .main-form-section .purple-span:after{content: '';display: block; border-right:10px solid #c2b8ed; border-top: 10px solid #c2b8ed; border-bottom: 10px solid transparent;
                border-left: 10px solid transparent; right: -8px; position: relative; top: -2px;}


.main-form-section .checkbox{margin-top: 5px;width: 30px; height: 30px; border-radius: 50px; text-align: center; border: 2px solid #0eb619; color: #0eb619;}
.main-form-section h2{font-weight: 300; font-size:48px; padding:30px 0px}
.main-form-section .ready{font-weight: 200; color: #13b7dd; font-size: 46px;}

@media screen and (max-width:904px) and (min-width:532px){
.main-form-section h2{ font-size:40px;}

}
@media screen and (max-width:531px) and (min-width:200px){

  .main-form-section h1{ font-size:36px;line-height: 22px;}
  .main-form-section h4{font-size: 16px;}
  .main-form-section h4:before{content: ''; width: 20px; }
  .main-form-section h4:after{content: ''; width: 20px; }
  .main-form-section .list-group{margin: 0px;}
  .main-form-section h2{ margin-bottom: 40px;font-size: 28px; padding: 2px 0px;}

}
@media screen and (max-width:649px){
.main-form-section h1{ margin-top:24px; }
.sign-up-section{display: block;}
}
@media screen and (max-width:1399px){
.body_section1{margin-top: 54px ;}
}
@media screen and (min-width:200px){
.body_section1{min-height:100%; display:block; padding:0px; margin:0px; height:calc(100vh)}
}

.custom-control-input { width: 20px; height: 20px;}
.custom-control-description {position: relative;top: -4px;font-size: 13px;}
.check-sec{min-height: 88px; display: flex;align-items: center;}
@media screen and (min-width:992px){
.bttn-down{min-height: 97px; position: relative;}
.down-bttn{position: absolute; right: 6px;bottom: 0px}
}



/*custom stylesheet*/

.addon-form-style{border-top-right-radius: 0px ;border-bottom-right-radius: 0px ;}
.main{width:100%;min-height:20px; margin:0px;padding:0px;font-family: 'Open Sans', sans-serif ;min-height:100%; height:auto}
.container{width:100%;max-width:1300px; margin:0 auto}
.d-block{ display:block }
label{font-size: 12px;}
.min-head-m-top{margin-top: 10px }
.stand-height{ min-height: 280px }
.xtra-vertical-pad{display: inline-block;;width:100%;padding:10px 0px }
.text-left {text-align: left ;}
.form-top{ margin-top:25px }
.thin-left-right-pad{padding-left: 7px ; padding-right: 7px}
.x-mini-pad{padding: 5px ;}

.para-m-top{ margin-top:14px }
.vertical-align-top{ vertical-align: top }
.sm-font{ font-size:16px }
.min-sm-font{ font-size:14px ; font-weight:500  }
.min-m-top{ margin-top:8px }
.middle{ display:block ; margin:0 auto ;}
.pad-top{padding-top:20px }
.pad-bot{ padding-bottom:12px }
.pad-all-side{padding: 14px ;}



.border-none{border:none !important }
.border-radius{ border-radius: 8px }
.mar-bot-zero{ margin-bottom: 0px }
.m-zero{margin:0px }
.mini-pad{ padding:8px }
.xtra-pad{ padding:15px }
.xtra-min-width { width: 70px ;}
.btn-info{ border: 1px double #53F8D9; padding: 8px 40px ; font-weight:500; border-radius:10px}
.btn-info:hover{padding: 8px 40px ;}
.special-btn{ border-radius:10px ;margin: 20px ; padding: 15px 60px ;text-transform:uppercase }

.drop-styl li a{padding: 5px 20px; }
.m-right{margin-right:12px }
.mid-font{font-size:18px }

.xtra-border-radius{border-radius: 20px ; border:1px solid #ccc }
.x-top-pad{margin-top: 4px }

a:active, a:focus, a:hover, a:active{text-decoration:none }

.m-top{ margin-top:20px }
.m-bot{ margin-bottom:12px }

.padding-left-zero{padding-left: 0px }
.big-mar-top { margin-top: 46px ;}
.border-deep-sky { border-color: #1aa4ab ;}
.pad-zero{ padding:0px }

.left-radius{border-top-left-radius:7px ;border-bottom-left-radius:7px }
.right-radius{border-top-right-radius:7px ;border-bottom-right-radius:7px }
table.dataTable thead .sorting::after{ display:none }
.rating-section{ width:100%; min-height:20px; padding:20px 0px}

.font-family-oswald { font-family: 'Oswald', sans-serif;}

.table-responsive .form-group { min-width: 145px;}

.simple-box{border-radius: 4px;line-height: 18px;font-size: 13px ;padding:10px; margin-bottom: 10px}

.border-radius-zero{ border-radius: 0px }
.border-top-radius-none{margin: 0px ; border-top-left-radius:0px ; border-top-right-radius:0px }
.border-top-radius{margin: 0px ; border-top-left-radius:6px ; border-top-right-radius:6px }
.user-image{width: 30px;height: 29px;max-width: 100%;}
.side-menu{ margin-left:-14px }
.xs-small{ font-size:12px }
.media-body p{ font-size:13px ; line-height:18px}

.media-object{ margin-top:6px }
.pagination{ float:right }
.close-side{padding:5px }
.sub-content{ width:100%; min-height:20px; padding:10px 0px}

.btn-default:hover{ padding:6px 15px }
.side { right: -300px; width: 300px;}
.side-bttn:hover{padding: 8px 10px 8px 10px ;}

.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th{
  text-align: center;border-color: #D8EBEF; font-size:13px; vertical-align:middle;color: #0B5872;}
.custom-table-height{ height:295px; overflow-y:auto; max-height:100%; width:100%}
.table{ margin-bottom:0px }
.sidenav { height: 97%; width: 0;position: fixed; z-index: 1; top: 0; right: 0;overflow-x: hidden;transition: 0.5s;padding-top: 0px;
text-align: justify;}

.head{ background:#2a98ad ; height:44px;}
.table > thead > tr > th{ font-size:14px;font-weight: 600;}
.sidenav a { padding: 0px;text-decoration: none;font-size: 25px;display: block; transition: 0.3s}


.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}
.med-font{ font-weight:500 }
.opera-font{font-family: 'Open Sans', sans-serif ;}
.icon {padding-top: 6px;font-weight: 500;font-size:17px ;float: right; font-family: 'Open Sans', sans-serif ;}
.drop-styl{ width:250px !important ; padding:5px 10px !important ;margin-left: 50px !important ;}
.drop-styl li a:hover{padding: 6px 15px !important; }
.table-responsive .checkbox, .radio{ margin-top:0px !important ; margin-bottom:0px }
.border{border: 1px solid #e9e8e8 }
#navbar-menu i{
    font-size: 15px;
}