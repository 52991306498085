html{background:#f3f3f4;}
body{background:#f3f3f4;}

nav.navbar li.dropdown ul.dropdown-menu > li a:hover{ color:#6de2fd ;border-bottom:1px solid #fff ;color:#000 ;transition: 1s ease-in-out;}
nav.navbar li.dropdown ul.dropdown-menu > li a{padding-bottom: 6px ; color: #8b8b8b ;background: #fff;border-color: transparent ;font-size: 14px;}
nav.bootsnav .megamenu-content .title{color: #000;padding-left:4px;text-transform: uppercase;padding-bottom: 10px;}
nav.navbar.bootsnav.no-background.white ul.nav > li > a {color: #7f7f7f;}
nav.navbar.bootsnav li.dropdown ul.dropdown-menu{border-top-color:transparent;background: #fff;box-shadow:0 4px 2px 0 rgba(227, 226, 226, 0.2), 0 6px 10px 0 rgba(189, 185, 185, 0.2);}
.select-style:after{background: url(../../../images/option-img1.png) no-repeat;}
.select-style1:after{background: url(../../../images/option-img1.png) no-repeat;}
.select-style{background: #fff url(../../../images/option-img1.png) no-repeat 98% 50% !important;}
select{border:none; box-shadow: none;background-image: none;}
option{ color:#000;}

.navbar-default .navbar-toggle:hover{background:none; color:#37d8f4;}
nav.navbar.bootsnav.navbar-fixed{background:#f3f3f3;box-shadow:0px 2px 2px #CECECE}
nav.navbar ul.nav li.dropdown.on > a,nav.navbar ul.nav > li > a:hover{color:#000 ;}
nav.navbar.bootsnav.menu-center ul.nav.navbar-center{background:#fff ;}
.navbar-nav > li{    background: #fff;
-webkit-border-image: -webkit-linear-gradient(bottom, #dadada, #f4f0f0, rgba(0, 0, 0, 0)) 1 100%;
-moz-border-image: -moz-linear-gradient(bottom, #dadada, #f4f0f0, rgba(0, 0, 0, 0)) 1 100%;
-o-border-image: -o-linear-gradient(bottom,#dadada, #f4f0f0, rgba(0, 0, 0, 0)) 1 100%;
border-image: linear-gradient(to top, #dadada, #f4f0f0, rgba(0, 0, 0, 0)) 1 4%;}

nav.navbar.bootsnav ul.nav > li > a{color:#595454;}
#btnFullscreen{border-radius:50%;;color: #fff;border: none;background: #946fb4;border: 4px solid #946fb4;}
header .top-search{background-color: rgb(8, 187, 216); border-bottom: 2px solid #59d6c1; box-shadow: 0px 1px 1px #dedede;}
header .top-search .form-control::-moz-placeholder {
color:#fff;
opacity:1;
}
header .top-search .form-control:-ms-input-placeholder {
color:#fff;
}
header .top-search .form-control::-webkit-input-placeholder {
color:#fff;
}

.side{background-color: rgba(1, 40, 56, 0.8) !important; color:#fff;}
.body_section{background:#f3f3f4;}
.graph_report_tabing, .heading{ background:-webkit-gradient(linear, 0% 0%, 0% 100%, from(#67c7e0), to(#248ca7)); color:#fff;}
.graph_report_tabing ul li a{color: #fff;border:1px solid #FFF3EA !important;background: rgba(249, 123, 76, 0.8);}
.graph-sec{background: #fff;}
.graph_report_tabing ul > .active a{color: #fff; font-weight:bold;border: 1px solid #dedede;border-bottom-color: transparent;background:rgba(84, 255, 229, 0.8);}


.heading{color: #F2FAFD;background: #13c3df;}



.form-control{color: #0a7d8c;border:1px solid #ccc;}
.graph_report_tabing{background: rgba(246, 183, 60, 0.8);}
div.dataTables_wrapper div.dataTables_length select{ background:url(../../../images/option-img1.png) no-repeat  95% 50%;}




table.dataTable td {border: 1px solid #e6e6e6;;}
table.dataTable th { border: 1px solid #1FA9C9;}
thead{ background: #2a98ad ;; color:#fff;}

table.dataTable.stripe tbody tr.odd, table.dataTable.display tbody tr.odd {
    background-color: #f9f9f9 ;
}

.pagination > li > a{
    background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#fff), to(#e3e8ef));
    background: -webkit-linear-gradient(top, #fff, #e3e8ef);
    background: -moz-linear-gradient(top, #fff, #e3e8ef);
    background: -ms-linear-gradient(top, #fff, #e3e8ef);
    background: -o-linear-gradient(top, #fff, #e3e8ef); color:#26b4ba}

.pagination > .active > a{ color: #fff ;background: #ed8484;border-color: #2dbfc5;}
.dataTables_scrollBody{ border:1px solid #dedede}


.identity-heading{border-bottom:1px solid #dedede; color:#727070 ;}
.identity-heading h4{color:#727070 ; }

.sliding-content a, a:hover{color:#30849c}
.btn-back{background: #f9f9f9;}
.ticket{ background:#fff;}
.ticket li a{ color:#fff ; }
.ticket h6{color: #fff ;}
.table-bordered>thead>tr>th {border: 1px solid #1FA9C9;; border-bottom-color: transparent;}
.icon-size{color: #75f9fe;}
.pass-word{color: #75f9fe;}
.lock-icon{border: 1px solid #6af6f1;background: #027877;color: #75f9fe;}
#login .input-group-addon{background-color: #02818d;border: 1px solid #19b6d9;}
.btn-success{background: #012e37; border-color: #0ABBD5;}
.btn-success:hover{box-shadow: 10px 10px 5px #10312d; border-color:#09e7cf;}
.pass-word a:hover{color:#25cdde !important}
.box-heading{color:#20ADAD;border-bottom: 2px double #28a4b0;}
footer{ background: #13c3df; box-shadow: 0px 3px 3px #010D16; }

footer h6{color:#fff;}


.btn-primary{background: #13c3df;/* Old browsers */ color: #fff;box-shadow: 0px 0px 0px #ccc;}
.btn-primary:hover{border:none!important;box-shadow: 10px 10px 5px grey;color: #fff;background: #068795;}
.btn-primary.active, .btn-primary:active, .open > .dropdown-toggle.btn-primary{
  background-image: -webkit-gradient (linear, left top, left bottom, from(#03591E), to(#0f691d));
    background-image: -webkit-linear-gradient(top, #03591E, #0f691d);
    background-image: -moz-linear-gradient(top, #03591E, #0f691d);
    background-image: -ms-linear-gradient(top, #03591E, #0f691d);
    background-image: -o-linear-gradient(top, #03591E, #0f691d);
    background-image: linear-gradient(top, #03591E, #0f691d);

    color: #fff;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .25), 0 2px 3px rgba(0, 0, 0, .1);
    box-shadow: 0 1px 1px rgba(12, 12, 12, 0.3), 0 2px 3px rgba(9, 1, 1, 0.1);
}
.font-strong{color:#039dae;}
.form-head u{box-shadow: 5px 5px 5px #959393;background:#219FA6;color:#fff}
.form-head u:after{border-top: 26px solid transparent;border-right: 20px solid transparent;border-bottom: 20px solid transparent;border-left: 20px solid #219FA6;}
.form-heads u{box-shadow: 5px 5px 5px #959393;background: #219FA6;color: #fff; }
.form-heads u::after{border-top: 23px solid transparent; border-right: 23px solid transparent;border-bottom: 23px solid transparent;border-left: 23px solid #219FA6;}

@media screen and (max-width:1390px) {
  nav.navbar.bootsnav .navbar-toggle{ color: #46b0d6;}
}

@media screen and (max-width:992px) {
nav.navbar.bootsnav .navbar-nav > li > a{border-bottom: solid 1px #0e6e81; }
nav.navbar.bootsnav ul.nav > li > a{background: #fff; box-shadow:5px 5px 5px #dadada}
}
.one-styl{background:#fff;}
.parametter-line{box-shadow: 5px 5px 5px #acaaaa;background: rgb(63,209,14); /* Old browsers */
background: -moz-linear-gradient(-45deg, rgba(63,209,14,1) 0%, rgba(255,201,94,1) 31%, rgba(244,171,0,1) 50%, rgba(237,71,0,1) 87%); /* FF3.6-15 */
background: -webkit-linear-gradient(-45deg, rgba(63,209,14,1) 0%,rgba(255,201,94,1) 31%,rgba(244,171,0,1) 50%,rgba(237,71,0,1) 87%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(135deg, rgba(63,209,14,1) 0%,rgba(255,201,94,1) 31%,rgba(244,171,0,1) 50%,rgba(237,71,0,1) 87%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3fd10e', endColorstr='#ed4700',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */}

.parametter-arrow{border-left: 13px solid transparent;border-right: 13px solid transparent;border-bottom: 24px solid transparent;border-top: 24px solid #33b0df;}
.parametter-arrow-back{border-left:17px solid transparent;border-right: 17px solid transparent;border-bottom: 29px solid transparent;border-top: 29px solid #fff;}
.parameter-detail{border-top:1px solid #ccc}
.attch, .load-icon { background-color: #2fa9b4;color: #fff;}

.input-group-addon{color: #fff; background-color: #F0FDFD; box-shadow: none ;}
.form-inner-sec {border: 1px solid #C6F4F8;box-shadow: none !important;}

.form-control::-moz-placeholder {
color:#908f8f;;

}
.form-control:-ms-input-placeholder {
color:#908f8f;;
}
.form-control::-webkit-input-placeholder {

color:#908f8f;
}

.finance-circle{box-shadow: 1px 3px 2px #7e7e7e;background:#ddffd3;border:10px solid #35a30a;}
.border-color-yellow{ border-color:#f8b707 }
.border-color-red{ border-color:#d2112e }

.trr-bg-green{background-color: rgba(21, 99, 57, 0.4);}
.trr-bg-dark-green { background-color: rgba(121, 187, 152, 0.6);}

.trr-bg-light-green{background-color: rgba(104, 123, 69, 0.6);}
.trr-bg-dark-green1{background-color: rgba(158, 175, 101, 0.6)}
.trr-bg-light-brown{background-color: rgba(178, 136, 146, 0.6)}.trr-bg-dark-brown{background-color: rgba(134, 71, 86, 0.6) ;}
.employee-hod-circle{background: url(../../../images/employee-circle1.png) no-repeat 50%;background-size: contain;}
.btn-warning:hover{ background:#0A6A6C; color:#fff}
.btn-warning { color: #0bc6d8;background-color: transparent;border: 1px double #27e4ec ;}

.employee-hod-circle h2{ color:#0592c5}
.employe-cont h6 { color: #0592c5;}
.inner-section { border:1px solid #b4f0ff;}
.nav-side-menu { background: #eeeded;;color: #e1ffff;}
.nav-side-menu .brand {background-color: #23282e;}
.nav-side-menu ul .active,
.nav-side-menu li .active { color:#fff ;}
a:active, a:focus, a:active{color:#fff ;}
.nav-side-menu ul .sub-menu li.active,
.nav-side-menu li .sub-menu li.active {
    color: #d19b3d;
}
.nav-side-menu ul .sub-menu li.active a,
.nav-side-menu li .sub-menu li.active a {
    color: #d19b3d;
}

.nav-side-menu ul .sub-menu li,
.nav-side-menu li .sub-menu li {background-color: #181c20;border-bottom: 1px solid #23282e;}

.nav-side-menu ul .sub-menu li:hover,
.nav-side-menu li .sub-menu li:hover {
    background-color: #020203;
}

.nav-side-menu li a{color: #525252;}
.nav-side-menu li a:hover {color:#fff}
.nav-side-menu li:hover { border-left-color: #6ae4fe;color:#fff ; background-color: #37bad6;}
.nav-side-menu .toggle-btn {background-color: #37bad6;color: #fff;}
.header1{background:#37bad6;color:#fff;}
.back-border{border:1px solid #37bad6;}
.input-group-addon { color: #17adce;border-radius: 20px;border-left-color: transparent ;}
.ratio-sec{color:#0a7d8c;border-right: 1px solid #16c5dc;;}


.fa-home{color:#13c3df;font-size: 15px;}
.fa-building-o{color:#f6b54f;font-size: 15px;}
.fa-user{color:#8adb62;font-size: 15px;}
.fa-users{color:#946fb4;font-size: 15px;}
.fa-list-alt{color:#343f77;font-size: 15px;}
.fa-bar-chart{color:#2477ad;font-size: 15px;}
.fa-bell-o{color:#00cbcc;font-size: 15px;}
.fa-cog{color:#eb623e;}

.circle-image{border-radius: 50%;border: 1px solid #dbd8d8;transform: scale(1.3);margin-top: 2px;}
.fa-search{color:#087b8d !important;}

.custom-btttn{border-radius: 50% !important;font-size: 25px;padding: 7px 16px;text-align: center;box-shadow: 0px 5px 4px #bcb9b9;background: #ea6060;
border-color: #ea6060 !important;}
.custom-btttn:hover{background:#00cacc !important}
.tr-bg1{background:#e7f9fc}
.fa-pencil{color:#4151a5 ;}
.fa-trash{color:#ea6060 }
.plus-clr{color:#13c3df ;}
.bg-pink{background:rgba(248, 130, 88, 0.7) ;;}
.bg-light-sky{background:rgba(22, 202, 185, 0.7) ;}
.bg-light-yellow{background:rgba(229, 165, 63, 0.7) ;}
.bg-light-green{background:rgba(137, 197, 43, 0.7) ;}
.bg-purple{background:rgba(169, 155, 230, 0.7) ;}
.bg-dark-purple{background:rgba(35, 20, 75, 0.7) ;}
nav.navbar.bootsnav.on ul.dropdown-menu.megamenu-content .content {padding: 2px;border: 4px solid rgba(255, 255, 255, 0.5);
border-radius: 5px;
}
.title1{color:#0aaeb0 }
.title2{color:#f28348 }
.title3{color:#da9d26}
.title4{color:#92c52b }
.sm-arrow1:after{color:#13c3df;}
.sm-arrow2:after{color:#f6b54f;}
.sm-arrow3:after{color:#8adb62;}
.sm-arrow4:after{color:#946fb4;}
.sm-arrow5:after{color:#343f77;}
.sm-arrow6:after{color:#2477ad;}
.sm-arrow7:after{color:#00cbcc;}
.sm-arrow8:after{color:#eb623e;}
.sm-arrow9:after{color:#c39518;}


nav.navbar.bootsnav li.dropdown ul.dropdown-menu > li a:hover, nav.navbar.bootsnav li.dropdown ul.dropdown-menu > li a:hover{ color: #13c3df;}
.border-top-clr1{border-top: 5px solid #f373a3 !important}
.nav-side-menu li {
    border-bottom: 1px solid #f7f7f7;
}
/* extra layout style start here*/
.other-head {background: #fff; border-top: 5px solid #f6b54f; border-radius: 0px;}
.form-control {
    color: #898989 ;
    border: 1px solid #e1e1e1;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;

    border-radius: 0px;
}
.radius-form{border-radius: 20px;border-top-color: #e1e1e1;border-left-color:#e1e1e1;border-right-color:#e1e1e1;}

.switch-circle-bttn:before{content: '';box-shadow: 1px 3px 2px #ccc;
display: inline-block;width: 50px;height: 50px;background: #13c3df url(../../../images/arrow1.png) 50% no-repeat;
border-radius: 50%;text-align: center;bottom: 1px;position: relative;;left: -15px; top: 20px;}
.switch-circle-bttn{border: none;}
.pad-none{padding:0px !important}
.back-border-none{border:none ; }

/* extra layout style end here*/


.bg-summery{background: #01bbbc ;}
.bg-contact{background: #8adb62 ;}
.bg-notes-purple{background: #946fb4 ;}
.bg-requeir-dark-purple{background: #343f77 }
.bg-report-dark-blue{background: #2477ad }
.bg-list-see-blue{background: #2477ad }
.bg-quotet-sky{background: #13c3df }
.bg-royal-green{background: #2a98ad }
.bg-dark-pink{background: #f57c7c }
.bg-pink{background: #f373a3 }
.bg-dark-yellow{background: #c39518 }
.bg-dark-green{background:#549953 }
.bg-light-yellow{background:#dcca06 }
.bg-light-green{background: #49c583 }

.shadow-boxes{width: 100%;box-shadow: 1px 0px 3px 2px #ede9e9;padding: 15px;display: inline-block;margin-bottom: 16px;}


.border-summer-clr{border-color: #58dccf ;}
.border-profile-clr{border-color: #f6b54f }
.border-contact-clr{border-color: #8adb62 }
.border-properties-clr{border-color: #946fb4 }
.border-staff-clr{border-color: #343f77 }
.border-invoice-clr{border-color: #2477ad }
.border-quote-clr{border-color: #13c3df }
.border-notes-clr{border-color: #edda09 }
.border-follow-clr{border-color: #549953 }
.border-support-clr{border-color: #614f8b }
.border-log-clr{border-color: #eb623e}
.border-list-clr{border-color: #3b90c7 }
.border-dark-pink{border-color: #f57c7c }
.border-light-green{border-color:#49c583 }
.border-dark-yellow{border-color:#c39518 }

.head-bg-yellow{background: #f6b54f }
.pink-head{background:#f57c7c }
.thead-bg1{background: #13c3df }
.switch-circle-bttn:hover{background: none; color: #1fa6a8 !important;font-weight: 800; transition: 1s ease-in}



.dash-tablist .nav > li > a{border-color: #ccc; background:#fff}

.drop-white-arrow:before{content: "";width: 0px;height: 0px;position: absolute;border-top: 21px solid transparent;border-right: 21px solid transparent;border-bottom:21px solid #fff;
border-left:21px solid transparent;right: 0%;left: 5%;top: -42px;box-shadow:0 4px -4px 0px rgba(227, 226, 226, 0.1), 0 4px 0px 0 rgba(189, 185, 185, 0.2) !important;}
.dash-tablist .nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover{background:#13c3df ; color:#fff}

.custom-border{background: #fff}
.fa-list{color:#10b4d7 ;}
.fa-calendar-check-o{color:#05ca38 ;}
.fa-suitcase{color:#8b69e0 ;}
.border-top{border-top: 1px solid #ccc ;}
.flex-section{width:100%; display: flex;padding:5px 0px}
.nowrap-space{white-space: nowrap }
.xsm-font{font-size:13px ;}


.high-light-sec{background: #edfbfd ;}
a.list-group-item:focus, a.list-group-item:hover, button.list-group-item:focus, button.list-group-item:hover {
    color: #555 !important;}


/*custom stylsheet*/

.addon-form-style{border-right-color: transparent ;}
.main{background:#f3f3f4; }
.form-control:focus {border-color:#ccc;box-shadow: 0px 2px 5px -1px #fff ;}
label{color: #04888f;}
.ticket h4{ color: #fff }
.infos{ background:#ebf8ff }
.text-hyper{ color:#585858 }
.clr-black{ color:#000 ;}
.clr-green{ color:#239a93 }
.clr-light-green { color: #07cc5f ;}
.clr-yellow {color: #f8b707 ;}
.clr-red {color: #f05050 ;}
.clr-white{ color:#0d848f }
.clr-deep-sky{color: #0890ad }
.btn-info{ box-shadow: 10px 10px 5px grey;border: 1px double #53F8D9;background: #1CB0BD; color:#fff; }
.btn-info:hover{background:#000 ; color:#09F}

.shadow{box-shadow:1px 1px 3px #e8e8e8}
a:active, a:focus, a:hover, a:active{color:#1c1d1c ;}
nav.navbar.no-background ul.nav > li > a:hover{color:#10b4d7 }

.table-bordered {border: 1px solid #34bad3;}
table.dataTable{border: 1px solid #e6dede;}
.bg-dark-green {background: #549953;}
.bg-light-ash{background:#edfafd }
.ash-border{border:1px solid #ccc }
.simple-box{border-bottom: 1px solid #ccc;}
.border-color-ash{border-color:#ccc }
.media-heading{ color:#727070 }
.input-group{ color:#282828 }
.custom-table-height{border:1px solid #eceeee;}
.table > tbody > tr > td, .table > tfoot > tr > td, .table > thead > tr > td{border-color:#eaeaea;color: #0B5872;}
.sidenav{background-color: rgba(188, 219, 255, 0.7);color: #1d269c;}
tbody{ border:1px solid #dedede}
.pad-right{padding-right: 10px }
.head{ background:#00cbcc ; }
.table > thead > tr > th{ color: #fff;}
.sidenav a:hover, .offcanvas a:focus{ color: #f1f1f1;}
.sidenav a {color: #000;}
.icon{color:#CDF0FD}
.circle{ border:2px solid #fff;box-shadow:0px 2px 2px #858080;}
.bg-yellow{ background:#ff9900 }
.bg-green{ background:#197400 }
.bg-red{ background:#dc3912 }
.bg-ash{ background:#8a8888 }
.bg-blue{ background:#3366cc }
.bg-sky{ background:#38d8f0 }
.color-white{ color:#fff }
.bg-back{background: #fff }
.clr-ash{ color:#727070 }
.clr-blue{ color: #08a2d5 ;}
.bg-sky{ background:#f8f8f8 ! }
.bg-white{background: #fff }
.border-deep-sky { border-color: #1aa4ab ;}
.bg-deep-sky{background:#33b0df }
.rating-section{ background:#fff;}
.ticket-bg1{background:#00cacc ;border-right: 1px solid #2cdee0;}
.ticket-bg2{background:#f6b54f;border-right: 1px solid #fbd397;}
.ticket-bg3{background:#e34e4e;border-right: 1px solid #e36a6a;}
.all-ticket:after{content: '';width: 85px;height: 47px;display: inline-block;background: url(../../../images/shadow-img1.png) no-repeat;
position: absolute;right: 0px;bottom: -2px;}

.bg-lg-sky{background: #e7f9fc}
.main-ash-bg{background: #f3f3f4;}
.main-white-bg{background: #fff;}
.sm-radius{border-radius: 6px !important; border: 1px solid #ccc}

/*mandatory important*/
.drop-styl {background: rgba(234, 240, 243, 0.6) !important;border-top:2px solid #a1dce0 !important}
.drop-styl li a{border:1px solid #13c3df !important ;display: inline-block;;color:#000 !important ;}
.drop-styl li a:hover{ color:#000; padding: 6px 15px !important; }
.drop-white-arrow{margin-top: 16px !important ;}
nav.navbar.bootsnav .navbar-toggle{ background-color: transparent !important; }
