/* ==============================================================

Template name : Bootsnav - Multi Purpose Header
Categorie : Bootstrap Menu in CSS
Author : adamnurdin01
Version : v1.0.0
Created : 02 Juni  2016
Last update : 02 Juni  2016

============================================================== */

/*
Color
=========================== */
nav.navbar.bootsnav ul.dropdown-menu.megamenu-content .content ul.menu-col li a:hover,
.side .widget ul.link li a:hover,
.side .widget ul.link li a:focus,
.check-list li:before,
ul.cart-list > li > h6 > a,
.attr-nav > ul > li > a:hover,
.attr-nav > ul > li > a:focus,
nav.navbar-sidebar ul.nav li.dropdown.on > a,
nav.navbar-sidebar .dropdown .megamenu-content .col-menu.on .title,
nav.navbar-sidebar ul.nav li.dropdown ul.dropdown-menu li a:hover,
nav.navbar ul.nav li.dropdown.on > a,
nav.navbar.navbar-inverse ul.nav li.dropdown.on > a,
nav.navbar-sidebar ul.nav li.dropdown.on ul.dropdown-menu li.dropdown.on > a,
nav.navbar .dropdown .megamenu-content .col-menu.on .title,
nav.navbar ul.nav > li > a:hover,
nav.navbar ul.nav li.active > a,
nav.navbar li.dropdown ul.dropdown-menu > li a:hover{
    color: #13c3df;
}

nav.navbar.navbar-transparent ul.nav > li > a:hover,
nav.navbar.no-background ul.nav > li > a:hover,
nav.navbar ul.nav li.scroll.active > a,
nav.navbar.navbar-dark ul.nav li.dropdown ul.dropdown-menu  > li > a:hover,
nav.navbar ul.nav li.dropdown.on > a,
nav.navbar-dark ul.nav li.dropdown.on > a{
    color: #f43438 ;
}

@media(max-width:920px){
    nav.navbar .dropdown .megamenu-content .col-menu ul > li > a:hover,
    nav.navbar.navbar-dark .dropdown .megamenu-content .col-menu .title:hover{
        color: #f43438 ;
    }
}

/*
Border
=========================== */
ul.cart-list > li.total > .btn{
    border-color: #f43438;
}

nav.navbar li.dropdown ul.dropdown-menu{
    border-top-color: #f43438 ;
}

/*
Background
=========================== */
ul.cart-list > li.total > .btn,
.attr-nav > ul > li > a span.badge,
nav.navbar .share ul > li > a:hover,
nav.navbar .share ul > li > a:focus{
    background-color: #f43438;
}

ul.cart-list > li.total > .btn:hover,
ul.cart-list > li.total > .btn:focus{
    background-color: #dc3236 ;
}
